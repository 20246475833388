import { useCallback, useContext } from "react"
import { systemService } from "../utils/systems"
import { SystemIcon } from "./SystemIcon/SystemIcon"
import { systemWindowDataEquals } from "./SystemWindow"
import { WinManagerContext } from "../contexts/win-manager.context"

export const System = ({ systemId }) => {
	const { openNewWindow } = useContext(WinManagerContext)

	const onClick = useCallback(() => openNewWindow({ systemId }, { equals: systemWindowDataEquals }), [openNewWindow, systemId])

	const system = systemService.get(systemId)
	return (
		<SystemIcon
			style={{ position: "relative", top: 0, left: 0 }}
			label={system.id}
			img={`${process.env.PUBLIC_URL}/icons/${system.icon || "missing.png"}`}
			onClick={onClick}
		/>
	)
}
