import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import * as en from "./locales/en.json"
import * as fr from "./locales/fr.json"

const resources = {
	en: { translation: en },
	fr: { translation: fr },
}

i18n.use(initReactI18next).init({
	resources,
	lng: window.localStorage.getItem("locale") || "en",
	interpolation: {
		escapeValue: false,
	},
})

i18n.on("languageChanged", (lng) => {
	window.localStorage.setItem("locale", lng)
})

export default i18n
