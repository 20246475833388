import { useState } from "react"
import albums from "./albums.json"
import styles from "./Gallery.module.css"
import { Album } from "./components/Album/Album"
import Lightbox from "yet-another-react-lightbox"
import Inline from "yet-another-react-lightbox/plugins/inline"
import Captions from "yet-another-react-lightbox/plugins/captions"
import Video from "yet-another-react-lightbox/plugins/video"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/captions.css"
import { CloseButton } from "../../components/CloseButton/CloseButton"
import { useTranslation } from "react-i18next"

export const Gallery = () => {
	const { t } = useTranslation()
	const [isUnlocked, setIsUnlocked] = useState(false)
	const [password, setPassword] = useState("")
	const [showAlbum, setShowAlbum] = useState(false)
	const [content, setContent] = useState([])

	const handleUnlock = () => {
		if (password.toLowerCase() === "baymax") {
			setIsUnlocked(true)
		}
	}

	return showAlbum ? (
		<>
			<div className={styles.closeContainer}>
				<CloseButton close={() => setShowAlbum(false)} />
			</div>

			<Lightbox className={styles.lightbox} styles={{ container: { backgroundColor: "#111113" } }} slides={content} plugins={[Inline, Captions, Video]} />
		</>
	) : isUnlocked ? (
		<div className={styles.gallery}>
			<h1>Albums</h1>
			<div className={styles.galleryGrid}>
				{albums.map((album) => (
					<Album key={album.name} setShowAlbum={setShowAlbum} setContent={setContent} {...album} />
				))}
			</div>
			<div className={styles.spotifyContainer}>
				<div className={styles.spotifyDesc}>
					<h2>{t("spotifyPlaylist")}</h2>
					<p>
						{t("spotifyDesc")}
					</p>
				</div>
				<div className={styles.spotify}>
					<iframe
						className={styles.spotifyIframe}
						src="https://open.spotify.com/embed/playlist/4sZ7QOqdIcbEGmU5Ox4r6P?utm_source=generator"
						width="100%"
						height="380"
                        title="Spotify playlist"
						allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
						loading="lazy"
					></iframe>
				</div>
			</div>
		</div>
	) : (
		<div className={styles.gallery}>
			<div className={styles.locked}>
				<div className={styles.lockedHeader}>
					<h1>{t("gallery")}</h1>
					<p className={styles.warning}>{t("passwordProtected")}</p>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						handleUnlock()
						setPassword("")
					}}
				>
					<input
						className={styles.passwordInput}
						onInput={(e) => setPassword(e.target.value)}
						value={password}
						type="password"
						placeholder={t("password")}
						autoComplete="off"
						data-lpignore="true"
					/>
					<button className={styles.passwordSubmit} type="submit">
						{t("unlock")}
					</button>
				</form>
				<small>
					{t("noteToSelf")}:
					<br />{t("noteToSelf2")}...
				</small>
			</div>
		</div>
	)
}
