import styles from "./Acknow.module.css"
import { useTranslation } from "react-i18next"

export const Acknow = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.acknow}>
			<h1 className={styles.acknowTitle}>{t("acknow")}</h1>
			<p>{t("acknow1")}</p>
			<img className={styles.img} src="/images/sponsors.png" alt="sponsors" />
			<p>{t("acknow2")}</p>
			<img className={styles.img} src="/images/jedd.png" alt="JEDD logo" />
			<p>{t("acknow3")}</p>
			<small>
				<strong>{t("acknowLegal")}</strong>
				<span dangerouslySetInnerHTML={{ __html: t("acknowLegal1", { interpolation: { escapeValue: false } }) }}></span>
			</small>
		</div>
	)
}
