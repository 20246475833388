import { useMemo, useState } from "react"
import { WinContext } from "../../contexts/win.context"
import { guid } from "../../utils/guid"
import { WinManagerContext } from "../../contexts/win-manager.context"
import { getMaxZIndex, focusedDescriptor } from "./tools/descriptors"
import { removeOneAndSetDescriptors } from "./tools/set-descriptors"

export const WinManager = ({ render, children }) => {
	const [descriptors, setDescriptors] = useState([])

	const winManagerContextValue = useMemo(
		() => ({
			openNewWindow: (payload, { equals }) => {
				setDescriptors((descriptors) => {
					const existing = equals ? descriptors.find((descriptor) => equals(payload, descriptor.payload)) : undefined
					if (existing) {
						return focusedDescriptor(descriptors, existing.id)
					}
					// add a new descriptor
					return descriptors.concat([
						{
							id: guid(),
							zIndex: 1 + getMaxZIndex(descriptors),
							payload,
							state: {},
						},
					])
				})
			},
		}),
		[]
	)

	const windowHandlers = useMemo(
		() => ({
			close: (id) => {
				removeOneAndSetDescriptors(setDescriptors, id)
			},
			focus: (id) => {
				setDescriptors((descriptors) => focusedDescriptor(descriptors, id))
			},
		}),
		[]
	)

	return (
		<WinManagerContext.Provider value={winManagerContextValue}>
			{children}
			<div style={{ position: "absolute", inset: 0, backgroundColor: "transparent", pointerEvents: "none" }}>
				{descriptors.map((descriptor) => (
					<GenericWin key={descriptor.id} descriptor={descriptor} render={render} {...windowHandlers} />
				))}
			</div>
		</WinManagerContext.Provider>
	)
}

const GenericWin = ({ descriptor, close, focus, render }) => {
	const contextValue = useMemo(
		() => ({
			zIndex: descriptor.zIndex,
			close: close.bind(null, descriptor.id),
			focus: focus.bind(null, descriptor.id),
		}),
		[descriptor, close, focus]
	)
	return (
		<WinContext.Provider key={descriptor.id} value={contextValue}>
			{render(descriptor.payload)}
		</WinContext.Provider>
	)
}
