import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import HomeMicrobot from "./components/HomeMicrobot"
import styles from "./Home.module.css"
import { useTranslation } from "react-i18next"

const MICROBOT_COUNT = 30
const LIGHT_POSITION = [0, 10, 0]

const DISTANCE_RANGE = [10, 20]
const OFFSET_RANGE = [-10, 10]

/**
 * This component is responsible for moving the camera around
 * when the mouse is moved
 */
function MovingCamera() {
	const camera = useThree((state) => state.camera)
	const [mouse, setMouse] = useState({ x: 0, y: 0, z: 5 })

	useFrame(() => {
		// Don't keep moving the camera if it's already close enough
		const closeAmount = 0.1
		const closeEnough =
			Math.abs(camera.position.x - mouse.x) < closeAmount &&
			Math.abs(camera.position.y - mouse.y) < closeAmount &&
			Math.abs(camera.position.z - mouse.z) < closeAmount

		if (closeEnough) return

		// Move the camera towards the mouse position
		camera.position.x += (mouse.x - camera.position.x) / 25
		camera.position.y += (mouse.y - camera.position.y) / 25
		camera.position.z += (mouse.z - camera.position.z) / 25
	})

	useEffect(() => {
		const onMouseMove = (e) => {
			// Move the camera when the mouse is moved
			const { clientX, clientY } = e
			const { innerWidth, innerHeight } = window

			// Update mouse state
			setMouse({
				x: (clientX / innerWidth) * -2 + 1,
				y: (clientY / innerHeight) * 2 - 1,
				z: document.documentElement.scrollTop / document.documentElement.scrollHeight + 5,
			})
		}

		window.addEventListener("mousemove", onMouseMove)
		return () => window.removeEventListener("mousemove", onMouseMove)
	}, [camera])

	return <perspectiveCamera />
}

function HomeThreeCanvas() {
	const canvasStyle = {
		filter: "blur(2px)",
		position: "fixed",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		zIndex: -2,
		top: 0,
	}

	const microbots = useMemo(
		() =>
			Array.from({ length: MICROBOT_COUNT }).map((_, i) => {
				// Randomize the distance and offset of the microbots
				const distance = Math.random() * (DISTANCE_RANGE[1] - DISTANCE_RANGE[0]) + DISTANCE_RANGE[0]
				const offset = Math.random() * (OFFSET_RANGE[1] - OFFSET_RANGE[0]) + OFFSET_RANGE[0]

				// A number between 0.1 and 0.3, with a random sign
				const speed = (Math.random() - 0.5 > 0 ? 1 : -1) * (Math.random() * 0.2 + 0.1)
				return <HomeMicrobot key={i} distance={distance} offset={offset} speed={speed} />
			}),
		[]
	)

	const openClasses = styles.canvas_visible
	return (
		<Canvas style={canvasStyle} className={openClasses}>
			<MovingCamera />
			<ambientLight position={LIGHT_POSITION} />
			<pointLight position={LIGHT_POSITION} intensity={3} />
			{microbots}
		</Canvas>
	)
}

export default function Home() {
	// To deal with the fade-in effect
	const { t, i18n } = useTranslation()

	const navigate = useNavigate()
	const onGetStartedClick = () => {
		// redirect to the boot page
		navigate("/boot")
	}

	return (
		<>
			<HomeThreeCanvas />
			{/* Page content goes here */}
			<div className={`${styles.container} ${styles.element_visible}`}>
				<section className={styles.section}>
					<h1 className={styles.home_title}>Vanier</h1>
					<h2 className={styles.home_subtitle}>{t("robotics")}</h2>
					<p className={styles.home_paragraph}>{t("homeDescription")}</p>
				</section>
				<section className={styles.section}>
					<div className={styles.card}>
						<div className={styles.cardHead}>
							<h2>{t("vit")}</h2>
							<span>{t("signIn")}:</span>
						</div>
						<div className={styles.user}>
							<img className={styles.userPic} src="/images/hiro.png" alt="logo" />
							<p className={styles.username}>Hiro Hamada</p>
						</div>
						<div className={styles.button_container}>
							<button className={styles.button_started} onClick={onGetStartedClick}>
								{t("enterDesktop")}
							</button>
						</div>
					</div>
				</section>
				<div className={styles.corner}>
					<div onClick={() => i18n.changeLanguage(i18n.language === "en" ? "fr" : "en")}>{t(i18n.language)}</div>
				</div>
			</div>

			{/* The small triangle at the bottom */}
			{/* <div className={`${styles.triangle} ${styles.element_visible}`}></div> */}
		</>
	)
}
