import { useEffect, useRef } from 'react';

export const useEffectOnUpdate = (effect, deps) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => unmount && unmount();
    } else {
      mounted.current = true;
    }
  // eslint-disable-next-line
  }, deps);

  // Reset on unmount for the next mount.
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
};
