import { useFrame } from "@react-three/fiber"
import { useState } from "react"

const ROTATION_FACTOR = 0.2
const MICROBOT_SIZE = [3, 0.75, 0.75]

/**
 * Distance: on X and Z axis
 * Offset: position on y axis
 */
export default function HomeMicrobot({ distance, offset, speed }) {
	// Rotation of the microbot on itself
	const [rotation, setRotation] = useState(Math.random() * 2 * Math.PI)

	// Theta is the rotation angle of the microbot on the XZ plane
	const [theta, setTheta] = useState(Math.random() * 2 * Math.PI)

	// Update the rotation and theta on each frame
	useFrame((state, delta) => {
		setTheta((theta) => theta + delta * speed)
		setRotation((rotation) => rotation + delta * ROTATION_FACTOR)
	})

	const position = [Math.cos(theta) * distance, offset, Math.sin(theta) * distance]

	return (
		<mesh position={position} rotation={[rotation, -rotation, rotation]}>
			<boxGeometry args={MICROBOT_SIZE} />
			<meshStandardMaterial color="black" />
		</mesh>
	)
}
