import { Time } from "../../../../components/Time"
import styles from "./Taskbar.module.css"
import { useTranslation } from "react-i18next"

export const Taskbar = () => {
	const { t, i18n } = useTranslation()

	return (
		<div className={styles.taskbar}>
			<div className={styles.subdiv}>
				<div className={styles.start}>
					<img src="/images/hiro.png" alt="logo" />
				</div>
				<div className={styles.exit}>
					<div onClick={() => (window.location.href = "/")}>{t("exitDesktop")}</div>
				</div>
			</div>
			<div className={styles.subdiv}>
				<div className={styles.language}>
					<div onClick={() => i18n.changeLanguage(i18n.language === "en" ? "fr" : "en")}>{t(i18n.language)}</div>
				</div>
				<div className={styles.time}>
					<Time />
				</div>
			</div>
		</div>
	)
}
