import React from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./Boot.module.css"
import { useTranslation } from "react-i18next"

export const Boot = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		// wait 5 seconds, then redirect to desktop
		const timeout = setTimeout(() => {
			navigate("/desktop")
			const audio = new Audio("/sounds/ubuntu-start.wav")
			audio.volume = 0.1
			audio.play()
		}, 3000)

		return () => clearTimeout(timeout)
	}, [navigate])

	return (
		<div className={styles.bg}>
			<div className={styles.user}>
				<img src="/images/hiro.png" alt="logo" />
				<h1>Hiro Hamada</h1>
			</div>
			<p className={styles.loading}>{t("loading")}</p>
		</div>
	)
}
