import { BlogPost } from "./BlogPost/BlogPost"
import { useState } from "react"

export const BlogList = ({ data, handleShowPost, handlePostId }) => {
	const [post, setPost] = useState(true)

	const handlePost = () => {
		setPost(post)
		handleShowPost()
	}

	const handleId = (title, text, source) => {
		handlePostId(title, text, source)
	}

	return data.map((blog) => <BlogPost post={blog} key={blog.id} handlePost={handlePost} handleId={handleId} />)
}
