import { systems } from "./data/systems"
import styles from "./Desktop.module.css"
import { System } from "./components/System"
import { WinManager } from "./components/Win/WinManager"
import { SystemWindow } from "./components/SystemWindow"
import { Taskbar } from "./components/Taskbar/Taskbar"
import { Note } from "./components/Note/Note"

function windowRenderer(payload) {
	return <SystemWindow systemId={payload.systemId} />
}

export const Desktop = () => {
	return (
		<WinManager render={windowRenderer}>
			<div className={styles.desktopContainer}>
				{systems && (
					<div className={styles.desktop} style={{ backgroundImage: "url('/images/bg.jpg')" }}>
						{systems.map((system) => (
							<div key={system.id} className={styles.desktopItem}>
								<System systemId={system.id} />
							</div>
						))}
					</div>
				)}
				<Note note={{title: "noteTitle", content: "noteContent"}} />
				<Taskbar />
			</div>
		</WinManager>
	)
}
