import styles from "./GameDesc.module.css"
import { useTranslation } from "react-i18next"

export const GameDesc = () => {
	const { t, i18n } = useTranslation()

	return (
		<div className={styles.gameDesc}>
			<h2>{t("gDt1")}</h2>
			<p>{t("gD1")}</p>
			<iframe
				src="https://icloud50641.autodesk360.com/shares/public/SH35dfcQT936092f0e4345c28183fd878d0f?mode=embed"
				width="100%"
				height="600"
				allowfullscreen="true"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				frameborder="0"
			></iframe>
			<h2>{t("gDt2")}</h2>
			<p>{t("gD2")}</p>
			<h2>{t("gDt3")}</h2>
			<p>{t("gD3")}</p>
			<iframe
				src="https://icloud50641.autodesk360.com/shares/public/SH35dfcQT936092f0e43a6a5a8d344fb8450?mode=embed"
				width="100%"
				height="600"
				allowfullscreen="true"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				frameborder="0"
			></iframe>
			<h2>{t("gDt4")}</h2>
			<p>{t("gD4")}</p>
			<img className={styles.img} src={"/images/points by level" + (i18n.language === "fr" ? "-fr" : "") + ".png"} alt="points by level" />
			<h2>{t("gDt5")}</h2>
			<p>{t("gD5")}</p>
			<img
				className={styles.img}
				src={"/images/regular piece vs yama piece" + (i18n.language === "fr" ? "-fr" : "") + ".png"}
				alt="regular piece vs yama piece"
			/>
			<h2>{t("gDt6")}</h2>
			<p>{t("gD6")}</p>
			<h2>{t("gDt7")}</h2>
			<p>{t("gD7")}</p>
		</div>
	)
}
