import React from "react"
import styles from "./BlogPost.module.css"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const BlogPost = ({ post: { id, title, description, text, source, sourceFr }, handlePost, handleId }) => {
	const { t, i18n } = useTranslation()
	const [showPost, setShowPost] = useState(false)

	const openPost = () => {
		setShowPost(!showPost)
	}

	const onClick = () => {
		openPost()
		handlePost(showPost)
		handleId(title, text, i18n.language === "fr" && sourceFr ? sourceFr : source)
	}

	return (
		<div className={styles.blogPostContainer} onClick={onClick}>
			<div className={styles.post}>
				<img src="/images/blogBox.svg" alt="box" className={styles.blogBox} />
				<div className={styles.blogBoxFX}>
					<div className={styles.blogBoxFXInner}>
						<img src="/images/circle.svg" alt="circle" className={styles.circleAnim} />
					</div>
				</div>
				<div className={styles.blogBoxFX}>
					<div className={styles.blogBoxFXInner}>
						<img src="/images/circle_line.svg" alt="circle_line" className={styles.circleLineAnim} />
					</div>
				</div>
				<div className={styles.textPost}>
					<h3>
						{t(title)}
						<br />
						<span className={styles.blogId}>
							{t("entry")} #{id}
						</span>
					</h3>
					<p>{t(text).substring(0, 95)}...</p>
				</div>
			</div>
		</div>
	)
}
