import React, { useState } from "react"

export const Time = () => {
	const [time, setTime] = useState(new Date().toLocaleTimeString())

	React.useEffect(() => {
		const timer = setInterval(() => {
			setTime(new Date().toLocaleTimeString())
		}, 1000)
		return () => {
			clearInterval(timer)
		}
	}, [])

	return <p>{time}</p>
}
