import styles from "./Album.module.css"
import { useTranslation } from "react-i18next"

export const Album = ({ setShowAlbum, setContent, name, nameFr, contents }) => {
	const { i18n } = useTranslation()
	const mediaToSlide = (album, media) => {
		// check if file is a video
		if (media.name.toLowerCase().includes(".mp4")) {
			return {
				type: "video",
				width: 1280,
				height: 720,
				title: i18n.language === "fr" ? media.nameFr : media.name,
				sources: [
					{
						src: "/images/gallery/" + album + "/" + media.name,
						type: "video/mp4",
					},
				],
			}
		} else {
			return { src: "/images/gallery/" + album + "/" + media.name, alt: media.name, title: i18n.language === "fr" ? media.nameFr : media.name }
		}
	}

	i18n.on("languageChanged", () => {
        setShowAlbum(false)
	})

	const handleClick = () => {
		const content = contents.map((content) => mediaToSlide(name, content))

		setContent(content)
		setShowAlbum(true)
	}

	return (
		<>
			<div className={styles.album} onClick={handleClick} style={{ backgroundImage: `url("/images/gallery/${name}/${contents[0].name}")` }}>
				<div className={styles.albumTitle}>
					<p>{i18n.language === "fr" ? nameFr : name}</p>
                    <small>{contents.length} {contents.length > 1 ? "photos" : "photo"}</small>
				</div>
			</div>
		</>
	)
}
