import { Team } from "../../Team/Team"
import { GameDesc } from "../../GameDesc/GameDesc"
import { ProgLog } from "../../ProgLog/ProgLog"
import { Vanier } from "../../Vanier/Vanier"
import { CRC } from "../../CRC/CRC"
import { Robot } from "../../Robot/Robot"
import { Gallery } from "../../Gallery/Gallery"
import { Acknow } from "../../Acknow/Acknow"

export const systems = [
	{
		id: "robot",
		icon: "robot.png",
		component: Robot,
	},
	{
		id: "gameDesc",
		icon: "gameDesc.png",
		component: GameDesc,
	},
	{
		id: "progLog",
		icon: "proglog.png",
		component: ProgLog,
	},
	{
		id: "team",
		icon: "team.png",
		component: Team,
	},
	{
		id: "gallery",
		icon: "gallery.png",
		component: Gallery,
	},
	{
		id: "vanier",
		icon: "vanier.png",
		component: Vanier,
	},
	{
		id: "crc",
		icon: "crc.png",
		component: CRC,
	},
	{
		id: "acknow",
		icon: "acknow.png",
		component: Acknow,
	},
]
