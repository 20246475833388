import styles from "./Team.module.css"
import { TeamRow } from "./components/TeamRow"
import { useTranslation } from "react-i18next"

export function Team({ width, height }) {
	const { t } = useTranslation()

	return (
		<div className={styles.team}>
			<div className={styles.teamRow}>
				<h2 className={styles.teamRowTitle}>{t("mentors")}</h2>
				<div className={styles.row}>
					<div className={styles.mentorsText}>
						<div className={styles.teamTextBlock}>
							<h3>{t("mentorsDescTitle")}</h3>
							<p className={styles.description}>{t("mentorsDesc")}</p>
						</div>
					</div>
					<div className={styles.mentors}>
						<div className={styles.teamCaptain + " " + styles.mentor}>
							<img className={styles.captainImage} src={"/portraits/Ashley Rankin.png"} alt="Ashley Rankin" loading="lazy" />
							<h3 className={styles.captainTitle}>Ashley Rankin</h3>
							<span>{t("mentor1Title")}</span>
						</div>
						<div className={styles.teamCaptain + " " + styles.mentor}>
							<img className={styles.captainImage} src={"/images/generic.png"} alt="Ashley Rankin" loading="lazy" />
							<h3 className={styles.captainTitle}>Anghelos Coulon</h3>
							<span>{t("mentor2Title")}</span>
						</div>
					</div>
				</div>
			</div>
			<TeamRow id="captainsTeam" width={width} />
			<TeamRow id="build" width={width} />
			<TeamRow id="robotProg" width={width} />
			<TeamRow id="competition" width={width} />
			<TeamRow id="kiosk" width={width} />
			<TeamRow id="web" width={width} />
			<TeamRow id="video" width={width} />
			<TeamRow id="journalism" width={width} />
		</div>
	)
}
