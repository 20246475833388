import React, { useState } from "react"
import { BlogList } from "./components/Blog/BlogList/BlogList"
import blogPosts from "./posts.json"
import styles from "./ProgLog.module.css"
import { BlogWindow } from "./components/Blog/BlogWindow/BlogWindow"
import { useTranslation } from "react-i18next"

export const ProgLog = () => {
	const { i18n } = useTranslation()
	const [post, setPost] = useState(false)
	const handleShowPost = () => {
		setPost(!post)
	}

	i18n.on("languageChanged", () => {
		setPost(false)
	})

	const [postTitle, setPostTitle] = useState("")
	const [postText, setPostText] = useState("")
	const [postSource, setPostSource] = useState()
	const handlePostId = (title, text, source) => {
		setPostTitle(title)
		setPostText(text)
		setPostSource(source)
	}

	return (
		<>
			{post ? (
				<BlogWindow close={handleShowPost} title={postTitle} text={postText} source={postSource} />
			) : (
				<div className={styles.container}>
					<div className={styles.blogListContainer}>
						<BlogList data={blogPosts} handleShowPost={handleShowPost} handlePostId={handlePostId} />
					</div>
				</div>
			)}
		</>
	)
}
