import React, { useState } from "react"
import styles from "./Note.module.css"
import { useTranslation } from "react-i18next"

export const Note = ({ note }) => {
	const { t } = useTranslation()
	const [expanded, setExpanded] = useState(false)

	return (
		<div className={styles.note} style={{ right: expanded ? "-1.5rem" : "-21.5rem" }} onClick={() => setExpanded(!expanded)}>
			<img src="/images/calendar.png" alt="calendar" className={styles.noteLogo} />
			<div className={styles.noteContent}>
				<span className={styles.noteTitle}>{t(note.title)}</span>
				<span className={styles.noteContent}>{t(note.content)}</span>
			</div>
		</div>
	)
}
