import React, { useCallback, useState } from "react"
import { systemService } from "../utils/systems"
import { Win } from "./Win/Win"

export const isSystemWindowData = (data) => {
	return data.hasOwnProperty("systemId")
}

export function systemWindowDataEquals(payloadA, payloadB) {
	return isSystemWindowData(payloadA) && isSystemWindowData(payloadB) && payloadA.systemId === payloadB.systemId
}

export const SystemWindow = ({ systemId }) => {
	const system = systemService.get(systemId)
	const [allowResize, setAllowResize] = useState(true)
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

	return (
		<Win
			title={system.id}
			img={system.icon ? `${process.env.PUBLIC_URL}/icons/${system.icon}` : ""}
			allowResize={allowResize}
			setSize={useCallback((width, height) => {
				setDimensions({ width, height })
			}, [])}
		>
			{system.component && React.createElement(system.component, { ...dimensions, systemId: system.id, setAllowResize })}
		</Win>
	)
}
