import styles from "./CRC.module.css"
import { useTranslation } from "react-i18next"

export const CRC = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.crc}>
			<h1>{t("crcTitle")}</h1>
			<p>{t("crcDesc")}</p>
			<div className={styles.row}>
				<img src="/images/crc.png" alt="CRC Logo" />
				<img src="/images/east.png" alt="EAST Logo" style={{ filter: "invert(1)" }} />
			</div>
		</div>
	)
}
