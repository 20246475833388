import { useEffect, useMemo, useState } from 'react';

export const useOnDrag = ({ onDragMove, draggable = true }) => {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    function onMouseMove(e) {
      if (isDragging && draggable) {
        onDragMove(e);
        e.preventDefault();
      }
    }
    function onMouseUp() {
      setIsDragging(false);
    }
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, onDragMove, draggable]);

  return useMemo(() => {
    const onMouseDown = () => {
      setIsDragging(true);
    };
    return { onMouseDown };
  }, []);
}
