import styles from "../Team.module.css"
import React from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { Member } from "./Member"
import members from "../members.json"
import { useTranslation } from "react-i18next"

export const TeamRow = ({ id, width }) => {
	const { t } = useTranslation()
	const [persona, setPersona] = React.useState(false)
	const [numberOfSlides, setNumberOfSlides] = React.useState(1)

	React.useEffect(() => {
		setNumberOfSlides(Math.floor(width / 250) || 1)
	}, [width])

	return (
		<div className={styles.teamRow}>
			<h2 className={styles.teamRowTitle}>{t(id)}</h2>
			<div className={styles.row}>
				<div className={styles.teamText}>
					<div className={styles.teamTextBlock}>
						<h3>{t(id + "TeamTitle")}</h3>
						<p
							className={styles.description}
							dangerouslySetInnerHTML={{ __html: t(id + "TeamDesc", { interpolation: { escapeValue: false } }) }}
						></p>
					</div>
				</div>
				<div className={styles.teamCaptain}>
					<div className={styles.teamCaptainImage} onMouseEnter={() => setPersona(true)} onMouseLeave={() => setPersona(false)}>
						<img
							className={styles.captainImage}
							src={"/portraits/" + members["captains"][id] + ".png"}
							alt={members["captains"][id]}
							loading="lazy"
						/>
						<img
							className={styles.captainImageHover}
							src={"/images/captains/" + members["captains"][id] + ".png"}
							alt={members["captainsPersona"][id]}
							loading="lazy"
						/>
					</div>
					<h3 className={styles.captainTitle}>{persona ? members["captainsPersona"][id] : members["captains"][id]}</h3>
					<span>{t(id === "captainsTeam" ? "teamCaptain" : "sub-teamCaptain")}</span>
				</div>
			</div>
			<div className={styles.col}>
				<h3>{t(id + "MembersTitle")}</h3>
				<CarouselProvider
					className={styles.membersCarousel}
					naturalSlideWidth={50}
					isIntrinsicHeight={true}
					totalSlides={members[id].length}
					visibleSlides={numberOfSlides}
				>
					<ButtonBack className={styles.arrowBtn}>
						<img src="/images/arr-left.svg" alt="arrow back" />
					</ButtonBack>
					<Slider>
						{members[id].map((member, index) => {
							return (
								<Slide index={index} key={member}>
									<Member name={member} />
								</Slide>
							)
						})}
					</Slider>
					<ButtonNext className={styles.arrowBtn + " " + styles.arrowRight}>
						<img src="/images/arr-right.svg" alt="arrow next" />
					</ButtonNext>
				</CarouselProvider>
			</div>
		</div>
	)
}
