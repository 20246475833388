import React, { useState } from "react"
import styles from "../Team.module.css"

export const Member = ({ name }) => {
	const [sources, setSources] = useState({ webp: "/portraits/webp/" + name + ".webp", png: "/portraits/" + name + ".png" })

	return (
		<div className={styles.member} style={{ backgroundImage: 'url("/images/member.svg")' }}>
			<picture>
				<source srcSet={sources.webp} type="image/webp" />
				<source srcSet={sources.png} type="image/png" />
				<img
					className={styles.memberImage}
					onError={(e) => {
						e.target.onerror = null
						setSources({ png: "/images/generic.png", webp: "/images/generic.webp" })
					}}
					src={"/portraits/webp/" + name + ".webp"}
					alt={name}
					loading="lazy"
				/>
			</picture>
			<p className={styles.memberName}>{name}</p>
		</div>
	)
}
