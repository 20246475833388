import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import styles from "./Robot.module.css"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"

export const Robot = ({ width, height, setAllowResize }) => {
	const { t, i18n } = useTranslation()

	useEffect(() => {
		setAllowResize(false)
	}, [setAllowResize])

	return (
		<Parallax pages={13}>
			<div className={styles.robotIntro}>
				<p style={{ fontSize: "1.1rem", width: "70%", minWidth: "20rem" }}>{t("robot-intro")}</p>
			</div>

			<ParallaxLayer sticky={{ start: 2.5, end: 13 }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_2_left.png"}
					alt="arm_2_left"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_2_right.png"}
					alt="arm_2_right"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_1_left.png"}
					alt="arm_1_left"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_1_right.png"}
					alt="arm_1_right"
				/>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 5.5, end: 13 }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_3_left.png"}
					alt="arm_3_left"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_3_right.png"}
					alt="arm_3_right"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_4_left.png"}
					alt="arm_4_left"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/arm_4_right.png"}
					alt="arm_4_right"
				/>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 8.5, end: 13 }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<img className={styles.robotPart} style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }} src={"/images/robot/head.png"} alt="head" />
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/hip_area.png"}
					alt="hip_area"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/leg_1_right.png"}
					alt="leg_1_right"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/leg_1_left.png"}
					alt="leg_1_left"
				/>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 12.5, end: 13 }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/leg_2_right.png"}
					alt="leg_2_right"
				/>
				<img
					className={styles.robotPart}
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
					src={"/images/robot/leg_2_left.png"}
					alt="leg_2_left"
				/>
				<img className={styles.robotPart} style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }} src={"/images/robot/torso.png"} alt="torso" />
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 1, end: 13 }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<img
					className={styles.silhouette}
					src={"/images/robot/silhouette.png"}
					alt="silhouette"
					style={{ scale: width > 900 && height > 520 ? "20%" : "17%" }}
				/>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 0, end: 13 }} style={{ display: "flex", justifyContent: "center" }}>
				<div className={styles.scroll}>
					<div className={styles.mouse}>
						<div className={styles.scroller}></div>
					</div>
				</div>
			</ParallaxLayer>

			<ParallaxLayer offset={0.75} speed={1} style={{ display: "flex", justifyContent: "center" }}>
				<h2 style={{ color: "white", fontSize: "1.2rem", fontWeight: "600" }}>{t("robot-scroll-to")}</h2>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 13, end: 13 }} style={{ display: "flex", justifyContent: "center" }}>
				<h3 className={styles.title}>{t("robot-complete")}</h3>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 10, end: 12 }} offset={5} style={{ display: "flex", justifyContent: "center" }}>
				<h3 className={styles.title}>{t("robot-title4")}</h3>
				<ParallaxLayer offset={6.35} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", gap: "50px" }}>
					<div className={styles.infoCard1} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text4-1")}</p>
					</div>
					<div>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src="/images/robot/robot_image/prototype_84_duct_tape_claw.jpg"
							alt="prototype_84_duct_tape_claw"
						/>
					</div>
					<div className={styles.infoCard2} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text4-2")}</p>
					</div>
					<div className={styles.infoCard1} style={{ width: width > 900 ? "25%" : "45%" }}>
						<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
							<iframe
								width="100%"
								height="400"
								src={"https://www.youtube-nocookie.com/embed/XHKkgp9_v3A"}
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen
							></iframe>
						</div>
					</div>
					<div>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src="/images/robot/robot_image/prototype_84_robot_pic1.jpg"
							alt="prototype_84_robot_pic1"
						/>
					</div>
				</ParallaxLayer>
				<ParallaxLayer offset={6.45} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", left: width > 900 ? "70%" : "50%" }}>
					<div className={styles.infoCard2} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text4-3")}</p>
						<p>{t("robot-text4-result")}</p>
					</div>
					<div>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src="/images/robot/robot_image/prototype_84_robot_pic2.jpg"
							alt="prototype_84_robot_pic2"
						/>
					</div>
				</ParallaxLayer>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 7, end: 9 }} style={{ display: "flex", justifyContent: "center" }}>
				<h3 className={styles.title}>{t("robot-title3")}</h3>
				<ParallaxLayer offset={5.25} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", gap: "50px" }}>
					<div className={styles.infoCard1} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text3-1")}</p>
					</div>
				</ParallaxLayer>
				<ParallaxLayer offset={5.35} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", left: width > 900 ? "70%" : "50%" }}>
					<div className={styles.infoCard2} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text3-result")}</p>
					</div>
				</ParallaxLayer>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 4, end: 6 }} style={{ display: "flex", justifyContent: "center" }}>
				<h3 className={styles.title}>{t("robot-title2")}</h3>
				<ParallaxLayer offset={3.25} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", gap: "50px" }}>
					<div className={styles.infoCard1} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text2-1")}</p>
					</div>
					<div className={styles.infoCard2} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text2-2")}</p>
					</div>
				</ParallaxLayer>
				<ParallaxLayer offset={3.35} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", left: width > 900 ? "70%" : "50%" }}>
					<div className={styles.infoCard2} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text2-result")}</p>
					</div>
					<div>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src={"/images/robot/robot_image/prototype_7_string_thing"+(i18n.language === "fr" ? "-fr" : "" )+".png"}
							alt="prototype_7_string_thing"
						/>
					</div>
				</ParallaxLayer>
			</ParallaxLayer>

			<ParallaxLayer sticky={{ start: 1, end: 3 }} style={{ display: "flex", justifyContent: "center" }}>
				<h3 className={styles.title}>{t("robot-title1")}</h3>
				<ParallaxLayer offset={1.25} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", gap: "50px" }}>
					<div className={styles.infoCard1} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text1-1")}</p>
					</div>
					<div>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src={"/images/robot/robot_image/prototype_1_step_1"+(i18n.language === "fr" ? "-fr" : "" )+".png"}
							alt="prototype_1_step_1"
						/>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src={"/images/robot/robot_image/prototype_1_step_3"+(i18n.language === "fr" ? "-fr" : "" )+".png"}
							alt="prototype_1_step_3"
						/>
					</div>
					<div className={styles.infoCard2} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text1-3")}</p>
					</div>
				</ParallaxLayer>
				<ParallaxLayer offset={1.35} speed={1.25} style={{ alignItems: "center", justifyContent: "flex-end", left: width > 900 ? "70%" : "50%" }}>
					<div className={styles.infoCard2} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text1-2")}</p>
					</div>
					<div>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src={"/images/robot/robot_image/prototype_1_step_2"+(i18n.language === "fr" ? "-fr" : "" )+".png"}
							alt="prototype_1_step_2"
						/>
						<img
							className={styles.imageCard1}
							style={{ width: width > 900 ? "25%" : "45%" }}
							src={"/images/robot/robot_image/prototype_1_final_product"+(i18n.language === "fr" ? "-fr" : "" )+".png"}
							alt="prototype_1_final_product"
						/>
					</div>
					<div className={styles.infoCardResult} style={{ width: width > 900 ? "25%" : "45%" }}>
						<p>{t("robot-text1-result")}</p>
					</div>
				</ParallaxLayer>
			</ParallaxLayer>
		</Parallax>
	)
}
