import { systems } from "../data/systems"

function getSystem(id) {
	const system = systems.find((system) => system.id === id)
	if (!system) {
		return {
			id,
			name: id,
		}
	}
	return system
}

export const systemService = {
	get: getSystem,
}
