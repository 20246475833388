import { createElement } from "react"
import styles from "./SystemIcon.module.css"
import { useTranslation } from "react-i18next"

export const SystemIcon = ({ icon, img, label, ...props }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.systemIcon} {...props}>
			<div>
				{img ? <img src={img} alt={label} /> : null}
				{icon && createElement(icon)}
			</div>
			<p>{t(label)}</p>
		</div>
	)
}
