import styles from "./BlogWindow.module.css"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import { CloseButton } from "../../../../../components/CloseButton/CloseButton"
import { useTranslation } from "react-i18next"

export const BlogWindow = (props) => {
	const { t } = useTranslation()

	const close = props.close
	const title = props.title
	const text = props.text
	return (
		<div className={styles.blogWindowContainer}>
			<div className={styles.blogWindowContent}>
				<div className={styles.blogWindowHeader}>
					<h2 className={styles.blogTitle}>{t(title)}</h2>
					<CloseButton close={close} />
				</div>
				<CarouselProvider className={styles.blogCarousel} isIntrinsicHeight={true} totalSlides={props.source.length} visibleSlides={1}>
					{props.source.length > 1 && (
						<ButtonBack className={styles.arrowBtn}>
							<img src="/images/arr-left.svg" alt="arrow back" />
						</ButtonBack>
					)}
					<Slider className={styles.carouselSlider}>
						{props.source.map((asset, index) => {
							return (
								<Slide index={index} key={index} className={styles.carousel__slide}>
									{asset === "[tutorial]" || asset === "[video]" ? (
										<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
											<iframe
												width="90%"
												height="400"
												src={"https://www.youtube-nocookie.com/embed/" + (asset === "[tutorial]" ? "dtLM82LLIGQ" : "gvZzrchceUU")}
												title="YouTube video player"
												frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
												allowfullscreen
											></iframe>
										</div>
									) : (
										<div style={{ display: "flex", width: "100%", height: "100%" }}>
											<img src={asset} alt="blog" className={styles.blogPostAsset} />
										</div>
									)}
								</Slide>
							)
						})}
					</Slider>
					{props.source.length > 1 && (
						<ButtonNext className={styles.arrowBtn + " " + styles.arrowRight}>
							<img src="/images/arr-right.svg" alt="arrow next" />
						</ButtonNext>
					)}
				</CarouselProvider>
				<p className={styles.blogText}>{t(text)}</p>
			</div>
		</div>
	)
}
