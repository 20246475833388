import { memo } from "react"
import { Button } from "./Button"
import styles from "../Win.module.css"
import { useTranslation } from "react-i18next"

export const Header = memo(({ allowResize, img, title, fullscreen, onFullScreenClick, onDoubleClick, onMouseDown, onCloseClick }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.winHeader} onDoubleClick={allowResize ? onDoubleClick : () => {}} onMouseDown={onMouseDown}>
			<div>
				{Boolean(img) && <img src={img} alt={title} />}
				<p>{t(title)}</p>
			</div>
			<div />
			<div>
				<Button type="fullscreen" onClick={allowResize ? onFullScreenClick : () => {}} />
				<Button type="close" onClick={onCloseClick} />
			</div>
		</div>
	)
})
