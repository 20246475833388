import { useCallback, useContext, useEffect, useState } from "react"
import { useOnDrag } from "../../hooks/use-on-drag"
import { useOnResize } from "../../hooks/use-on-resize"
import { Header } from "./components/Header"
import { WinContext } from "../../contexts/win.context"
import { minMax } from "./tools/min-max"
import styles from "./Win.module.css"

function randomOffset(size, percent) {
	const sign = Math.sign(Math.random() - 0.5)
	return sign * Math.floor(((size * percent) / 100) * Math.random())
}

const WinMinSize = 250

export const Win = ({ img, title, allowResize, setSize, children }) => {
	const { zIndex, close, focus } = useContext(WinContext)
	const [resizing, setResizing] = useState(false)
	const [{ top, left, width, height, cursor, fullscreen }, setProperties] = useState(() => {
		const height = window.innerHeight / 1.6 + 100
		const width = window.innerWidth / 2 + 200
		const randX = randomOffset(window.innerWidth - width, 20)
		const randY = randomOffset(window.innerHeight - height, 20)
		return {
			width,
			height,
			top: Math.floor((window.innerHeight - height) / 2) + randY,
			left: Math.floor((window.innerWidth - width) / 2) + randX,
			cursor: "auto",
			fullscreen: false,
		}
	})

	useEffect(() => {
		setSize(width, height)
	}, [width, height, setSize])

	const onCursorChange = useCallback((cursor) => setProperties((properties) => ({ ...properties, cursor })), [])

	const onResize = useCallback((offset) => {
		setProperties((properties) => {
			let top = properties.top + offset.top
			let left = properties.left + offset.left
			let height = properties.height + offset.bottom - offset.top
			let width = properties.width + offset.right - offset.left

			// keep top side in the window
			if (top < 0) {
				height += top
				top = 0
			}

			// keep left side in the window
			if (left < 0) {
				width += left
				left = 0
			}

			// respect min height
			if (height < WinMinSize) {
				if (offset.top) {
					top -= WinMinSize - height
				}
				height = WinMinSize
			}

			// respect min width
			if (width < WinMinSize) {
				if (offset.left) {
					left -= WinMinSize - width
				}
				width = WinMinSize
			}

			// keep left side in the window
			if (left + width > window.innerWidth) {
				width = window.innerWidth - left
			}

			// keep bottom side in the window
			if (top + height > window.innerHeight) {
				height = window.innerHeight - top
			}

			const updated = { ...properties, top, left, width, height }

			return arePropertiesEquals(properties, updated) ? properties : updated
		})
	}, [])

	const toggleFullScreen = useCallback(() => {
		setProperties((properties) => ({ ...properties, fullscreen: !properties.fullscreen }))
	}, [])

	const onMouseDown = useCallback(() => focus(), [focus])

	const onDragMove = useCallback((e) => {
		setProperties((properties) => ({
			...properties,
			top: minMax(0, properties.top + e.movementY, window.innerHeight - properties.height),
			left: minMax(0, properties.left + e.movementX, window.innerWidth - properties.width),
		}))
	}, [])

	// Resize the box when the window size change (reduce it size, and move its position)
	useEffect(() => {
		const onWindowResize = () => {
			setProperties((properties) => {
				let { top, left, width, height } = properties
				if (left + width > window.innerWidth) {
					width = window.innerWidth - left
					if (width < WinMinSize) {
						left = Math.max(0, left - (WinMinSize - width))
						width = WinMinSize
					}
				}
				if (top + height > window.innerHeight) {
					height = window.innerHeight - top
					if (height < WinMinSize) {
						top = Math.max(0, top - (WinMinSize - height))
						height = WinMinSize
					}
				}
				const updated = { ...properties, top, left, width, height }
				return arePropertiesEquals(properties, updated) ? properties : updated
			})
		}

		window.addEventListener("resize", onWindowResize)
		return () => window.removeEventListener("resize", onWindowResize)
	}, [])

	return (
		<div
			className={styles.win}
			style={fullscreen ? { inset: 5, zIndex } : { top, left, width, height, cursor, zIndex }}
			{...useOnResize({
				allowResize,
				onCursorChange,
				onResize,
				onChange: setResizing,
				resizable: !fullscreen,
				onMouseDown,
			})}
		>
			<Header
				img={img}
				title={title}
				fullscreen={fullscreen}
				onDoubleClick={toggleFullScreen}
				onCloseClick={close}
				allowResize={allowResize}
				onFullScreenClick={toggleFullScreen}
				{...useOnDrag({ draggable: !fullscreen && !resizing, onDragMove })}
			/>
			<div className={styles.winContent}>{children}</div>
		</div>
	)
}

function arePropertiesEquals(source, target) {
	const keys = Object.keys(source)
	return Object.keys(target).length === keys.length && !keys.some((key) => source[key] !== target[key])
}
