import "./App.css"
import Home from "./pages/Home/Home"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Desktop } from "./pages/Desktop/Desktop"
import { Boot } from "./pages/Boot/Boot"
import { Team } from "./pages/Team/Team"
import { GameDesc } from "./pages/GameDesc/GameDesc"
import { ProgLog } from "./pages/ProgLog/ProgLog"
import { Vanier } from "./pages/Vanier/Vanier"
import { CRC } from "./pages/CRC/CRC"
import { Robot } from "./pages/Robot/Robot"
import { Gallery } from "./pages/Gallery/Gallery"
import { Acknow } from "./pages/Acknow/Acknow"

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/">
					<Route index element={<Home />} />
					<Route path="desktop" element={<Desktop />} />
					<Route path="boot" element={<Boot />} />
					<Route path="team" element={<Team />} />
					<Route path="game-desc" element={<GameDesc />} />
					<Route path="prog-log" element={<ProgLog />} />
					<Route path="vanier" element={<Vanier />} />
					<Route path="crc" element={<CRC />} />
					<Route path="robot" element={<Robot />} />
					<Route path="gallery" element={<Gallery />} />
					<Route path="acknow" element={<Acknow />} />
					<Route path="*" element={<h1>404</h1>} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default App
