import styles from "./CloseButton.module.css"

export const CloseButton = ({ close }) => {
	return (
		<div className={styles.closeButton} onClick={close}>
			<div className={styles.firstLine}></div>
			<div className={styles.secondLine}></div>
		</div>
	)
}
