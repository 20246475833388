import styles from "./Vanier.module.css"
import { useTranslation } from "react-i18next"

export const Vanier = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.vanier}>
			<h1>{t("aboutVanierTitle")}</h1>
			<p>{t("aboutVanier1")}</p>
			<img alt="Vanier exterior" src="/images/pic_of_vanier.png" />
			<p>{t("aboutVanier2")}</p>
			<img className={styles.imgLogo} alt="Vanier logo" src="/images/vanier_logo.png" />
		</div>
	)
}
